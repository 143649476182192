<template>
    <div class="orders">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h4 class="box-title">Sanciones </h4>
            </div>
            <div class="card-body--">
              <div class="table-stats order-table ov-h">
                <table class="table ">
                  <thead>
                    <tr>
                      <th class="serial">#</th>
                      <th>Ciclo</th>
                      <th>Commentario</th>
                      <th class="serial">Tipo</th>
                      <th class="serial">Nivel</th>
                      <th>Fecha</th>
                      <th class="serial">Activo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in list_data" :key="index">
                      <td class="serial">{{index + 1}}</td>
                      <td>{{item.ciclo_name}}</td>
                      <td> {{item.comment}} </td>
                      <td class="serial">  <span class="name">{{item.tipo_display}}</span> </td>
                      <td class="serial"> <span class="product">{{item.level_display}}</span> </td>
                      <td><span class="count">{{item.date_sancion}}</span></td>
                      <td class="serial">
                        <span v-if="item.status" class="badge badge-complete">
                          {{item.status|status_to_text}}</span>
                        <span v-else class="badge badge-pending">
                          {{item.status|status_to_text}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> <!-- /.table-stats -->
            </div>
          </div> <!-- /.card -->
        </div>  <!-- /.col-lg-8 -->
        </div>
    </div>
</template>
<script>

/* eslint-disable */
export default {
  name: 'Sanciones',
  created(){
    this.list();
  },
  data() {
    return {
      list_data: [],
      url: 'sanciones/'
    };
  },
  methods: {
    list() {
      const payload = {};
      const self = this;
      this.sendRequest(payload).then((data) => {
        self.list_data = data.data;            
      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible autenticar.', 'error');
      });
      return null;
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url, payload);
      return result;
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
};

</script>
